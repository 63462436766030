<template>
  <div class="add-export-stock">
    <KTCodePreview v-bind:title="'Thông tin phiếu xuất kho'">
      <template v-slot:preview>
        <b-row class="col-md-12 mb-1 m-0 p-0" v-if="purchaseOrderId">
          <div class="alert-header w-100">
            Tạo phiếu xuất từ phiếu yêu cầu nhập hàng:
            <span
              class="text-primary orderCode"
              @click="linkToPO(poCode)"
              style="cursor: pointer"
              >{{ poCode }}</span
            >
          </div>
        </b-row>
        <b-row class="mb-5">
          <!-- INFO INPUT CONTROLS -->
          <b-col lg="6" md="6" sm="12">
            <b-row class="pl-0">
              <b-form-group class="col-6 required-control">
                <label>Ngày xuất kho:</label>
                <date-picker
                  disabled
                  placeholder="Chọn ngày xuất kho"
                  class="form-control form-control-sm"
                  :config="dpConfigs.date"
                  v-model="$v.form.dateImport.$model"
                  :state="validateState('dateImport')"
                  aria-describedby="input-date-feedback"
                ></date-picker>
                <b-form-invalid-feedback id="input-live-feedback"
                  >Vui lòng chọn ngày xuất kho</b-form-invalid-feedback
                >
              </b-form-group>
              <b-form-group class="col-6">
                <template>
                  <span>Loại xuất kho:</span>
                </template>
                <b-form-select
                  class="mt-2"
                  size="sm"
                  v-model="selectedStockSlip"
                  :options="stockSlipOptions"
                ></b-form-select>
              </b-form-group>
            </b-row>

            <b-row class="pl-0">
              <b-form-group class="col-6 required-control">
                <label>Kho:</label>
                <vue-autosuggest
                  class="border-radius-none"
                  :suggestions="filteredOptions"
                  @selected="onSelectedStore"
                  :limit="200"
                  @input="onInputChange"
                  :input-props="inputPropStore"
                  :should-render-suggestions="
                    (size, loading) => size >= 0 && !loading
                  "
                  v-model="$v.searchStore.$model"
                >
                  <div
                    slot-scope="{ suggestion }"
                    style="display: flex; align-items: center"
                  >
                    <div style="{ display: 'flex', color: 'navyblue'}">
                      {{ suggestion.item.suggestionName }}
                    </div>
                  </div>
                </vue-autosuggest>
              </b-form-group>
              <b-col v-if="showSelectProvider">
                <b-form-group class="required-control">
                  <label>Nhà cung cấp:</label>
                  <b-input-group>
                    <vue-autosuggest
                      class="border-radius-none"
                      :suggestions="filteredOptionsProvider"
                      @selected="onSelectedProvider"
                      :limit="200"
                      @input="onInputChangeProvider"
                      :input-props="{
                        class: 'autosuggest__input',
                        placeholder: 'Chọn nhà cung cấp',
                        style: 'border-radius:0px!important',
                      }"
                      v-model="searchProvider"
                    >
                      <div
                        slot-scope="{ suggestion }"
                        style="display: flex; align-items: center"
                      >
                        <div style="{ display: 'flex', color: 'navyblue'}">
                          {{ suggestion.item.suggestionName }}
                        </div>
                      </div>
                    </vue-autosuggest>
                    <b-input-group-append>
                      <span
                        @click="showOriginalModel = !showOriginalModel"
                        class="input-group-text sub-add"
                      >
                        <i class="fas fa-plus"></i>
                      </span>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <b-form-group>
                  <label>Nội dung:</label>
                  <b-form-textarea
                    size="sm"
                    v-model="notes"
                    :placeholder="'Thêm nội dung...'"
                    :rows="6"
                    :max-rows="6"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="pl-0">
              <b-form-group class="col-6">
                <template>
                  <span>Tổng tiền:</span>
                </template>
                <p class="mt-2">{{ formatMoney(totalPrice) }}</p>
              </b-form-group>
            </b-row>
          </b-col>
          <!-- INFO VAT INPUT CONTROLS -->
          <b-col lg="3" md="3" sm="12" v-if="showInfoVat">
            <b-form-group>
              <label>Chiết khấu:</label>
              <b-row>
                <b-col class="pr-0" cols="5">
                  <b-form-select
                    class="select-style"
                    v-model="selectDiscountType"
                    :options="listAmountType"
                    size="sm"
                    value-field="id"
                    text-field="name"
                  >
                  </b-form-select>
                </b-col>
                <b-col class="pl-0" cols="7">
                  <b-form-input
                    class="input-style text-right"
                    size="sm"
                    v-model="discountAmount"
                    v-mask="formater.currency"
                    placeholder="Chiết khấu"
                  ></b-form-input>
                </b-col>
              </b-row>
            </b-form-group>

            <b-form-group>
              <label>VAT:</label>
              <b-row>
                <b-col class="pr-0" cols="5">
                  <b-form-select
                    class="select-style"
                    v-model="selectVatType"
                    :options="listAmountType"
                    size="sm"
                    value-field="id"
                    text-field="name"
                    disabled-field="notEnabled"
                  >
                  </b-form-select>
                </b-col>
                <b-col class="pl-0" cols="7">
                  <b-form-input
                    class="input-style text-right"
                    size="sm"
                    type="text"
                    v-model="vatAmount"
                    v-mask="formater.currency"
                    placeholder="VAT"
                  ></b-form-input>
                </b-col>
              </b-row>
            </b-form-group>

            <b-form-group>
              <label>Số hóa đơn VAT:</label>
              <b-form-input
                class="input-style"
                size="sm"
                type="text"
                v-model="vatCode"
                placeholder="Số hóa đơn VAT"
              ></b-form-input>
            </b-form-group>
            <b-form-group class="required-control">
              <label>Ngày xuất VAT:</label>
              <date-picker
                placeholder="Chọn ngày xuất VAT"
                class="form-control form-control-sm"
                :config="dpConfigs.date"
                v-model="vatDate"
              ></date-picker>
              <b-form-invalid-feedback
                :state="validateState('vatDate')"
                v-if="!$v.vatDate.required"
                >Yêu cầu nhập Ngày xuất VAT.
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <!-- PAYMENT INPUT CONTROLS -->
          <b-col
            lg="3"
            md="3"
            sm="12"
            v-if="
              selectedStockSlip !== STOCK_SLIP_TYPE.EXPORT_WARRANTY_TO_PROVIDER
            "
          >
            <b-row>
              <b-col cols="12">
                <b-form-group>
                  <label for="input-name">Tiền mặt</label>
                  <b-form-input
                    class="input-style text-right"
                    size="sm"
                    type="text"
                    v-model="cashAmount"
                    placeholder="Nhập số tiền mặt"
                    v-mask="formater.currency"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group :label="'Tài khoản tiền mặt'">
                  <Autosuggest
                    class="border-radius-none"
                    :model="searchCash"
                    :suggestions="filteredOptionsCash"
                    placeholder="tài khoản tiền mặt"
                    :limit="200"
                    @select="onSelectedCash"
                    @change="onInputChangeCash"
                    suggestionName="suggestionName"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <b-form-group>
                  <label for="input-name">Tiền chuyển khoản</label>
                  <b-form-input
                    class="input-style text-right"
                    size="sm"
                    type="text"
                    v-model="transferAmount"
                    placeholder="Nhập số tiền"
                    v-mask="formater.currency"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="'Tài khoản chuyển khoản'"
                  label-for="select-accountant"
                  ><Autosuggest
                    class="border-radius-none mt-2"
                    :model="searchTransfer"
                    :suggestions="filteredOptionsTransfer"
                    placeholder="tài khoản chuyển khoản"
                    :limit="200"
                    @select="onSelectedTransfer"
                    @change="onInputChangeTransfer"
                    suggestionName="suggestionName"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group :label="'Ngày thanh toán'">
                  <date-picker
                    placeholder="Chọn ngày thanh toán"
                    class="mb-2 mt-2 form-control form-control-sm"
                    :config="dpConfigs.date"
                    v-model="payDate"
                  ></date-picker>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col md="2" class="pr-0">
            <b-form-select
              class="select-style"
              v-model="selectTypeSearch"
              :options="listTypeSearch"
              size="sm"
              value-field="id"
              text-field="name"
            ></b-form-select>
          </b-col>
          <b-col md="4" class="pl-0">
            <vue-autosuggest
              v-model="searchProduct"
              :suggestions="filteredOptionsProduct"
              @selected="onSelectedProduct"
              :limit="10"
              @input="onInputChangProduct"
              :input-props="inputSearchProductProp"
              :should-render-suggestions="
                (size, loading) => size >= 0 && !loading && searchProduct !== ''
              "
            >
              <div
                slot-scope="{ suggestion }"
                style="display: flex; align-items: center"
              >
                <div style="{ display: 'flex', color: 'navyblue'}">
                  {{ suggestion.item.name }}
                </div>
              </div>
            </vue-autosuggest>
          </b-col>
          <b-col md="6">
            <!-- <div class="d-flex justify-content-end">
              <b-button
                variant="secondary"
                size="sm"
                class="font-weight-bolder"
                @click="showModalImportProduct"
                :disabled="!inventorySelected"
              >
                <i style="font-size: 1rem" class="far fa-file-excel"></i>Nhập SP
                từ excel
              </b-button>
            </div> -->
          </b-col>
        </b-row>
        <ImportExcelStockSlip
          ref="stock-slip-excel"
          :type="selectedStockSlip"
          :storeId="inventorySelected"
          v-on:validData="validData"
        />
        <ProductStockInfoModal
          ref="product-stock-info"
          :productItem="selectedProductStock"
          v-on:updateProductStockInfo="handleUpdateProductStockInfo"
        />
        <span class="font-weight-bolder">Danh sách sản phẩm:</span>
        <table
          class="table table-bordered table-vertical-center table-hover col-12 mt-4"
        >
          <thead>
            <tr>
              <th scope="col" class="title-center" style="width: 20%">
                Tên sản phẩm
              </th>
              <!-- <th scope="col" class="title-center" style="width: 5%">Tồn</th> -->
              <th scope="col" class="title-center" style="width: 19%">
                Số khung - Số máy / Số serial
              </th>
              <th scope="col" class="title-center" style="width: 14%">
                Số lượng
              </th>
              <th scope="col" class="title-center" style="width: 20%">
                Đơn giá
              </th>
              <th scope="col" class="title-center" style="width: 15%">
                Thành tiền
              </th>
              <th scope="col" class="title-center" style="width: 10%"></th>
            </tr>
          </thead>

          <tbody v-for="(item, index) in listProductStock" :key="item.id">
            <ProductStock
              ref="product_stock"
              v-bind:productItem="item"
              v-bind:stockSlipType="selectedStockSlip"
              v-on:cancelAdd="cancelAdd"
              v-on:save="save"
              v-on:deleteItem="deleteItemInArray"
              v-on:update="updateItem"
              v-on:productStockModal="openProductStockModal"
              v-bind:editState="true"
              v-bind:index="index"
            />
          </tbody>
          <tr>
            <td :colspan="2" class="tdTextAlignPrice">Tổng</td>
            <td class="tdTextAlignPrice">{{ formatMoney(totalQuantity) }}</td>
            <td></td>
            <td class="tdTextAlignPrice">{{ formatMoney(totalPrice) }}</td>
            <td></td>
          </tr>
        </table>

        <div class="mb-5 mt-5">
          <b-overlay
            :show="isBusy"
            opacity="0.6"
            spinner-variant="white"
            spinner-type="grow"
            spinner-small
          >
            <b-button
              v-show="listProductStock.length > 0"
              v-bind:style="btnCreate"
              variant="primary"
              size="sm"
              class="font-weight-bolder"
              @click="onSubmit"
            >
              Lưu
            </b-button>
          </b-overlay>
          <b-modal
            v-model="showOriginalModel"
            hide-footer
            title="Thêm mới nhà cung cấp"
          >
            <b-row class="mb-5">
              <b-col>
                <label class="labelInput">Tên nhà cung cấp:</label>
                <b-form-input
                  v-model="providerName"
                  size="sm"
                  placeholder="Nhập tên nhà cung cấp"
                ></b-form-input>
              </b-col>
              <b-col>
                <label class="labelInput">Số điện thoại nhà cung cấp:</label>
                <b-form-input
                  v-model="providerPhone"
                  size="sm"
                  placeholder="Nhập số điện thoại nhà cung cấp"
                ></b-form-input>
              </b-col>
            </b-row>
            <b-row class="ml-1">
              <b-overlay
                :show="isBusy"
                opacity="0.6"
                spinner-variant="white"
                spinner-type="grow"
                spinner-small
              >
                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  @click="createProvider()"
                  >Lưu</b-button
                >
              </b-overlay>
              <b-button
                @click="showOriginalModel = !showOriginalModel"
                style="margin-left: 10px; font-weight: 600; width: 70px"
                variant="secondary"
                size="sm"
                >Hủy</b-button
              >
            </b-row>
          </b-modal>
        </div>
      </template>
    </KTCodePreview>
  </div>
</template>

<style lang="css" scoped>
.title-center {
  text-align: center;
}

.add-export-stock /deep/ .bv-no-focus-ring {
  padding-bottom: 0;
}

.add-export-stock /deep/ .autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.add-export-stock /deep/ .autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
  overflow: scroll;
  max-height: 200px;
  position: absolute;
}

.add-export-stock /deep/.autosuggest__results-container {
  position: relative;
  width: 100%;
}

.add-export-stock /deep/.autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

.add-export-stock /deep/.autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.add-export-stock /deep/.autosuggest__results-item:hover {
  background-color: #ddd;
}

.add-export-stock /deep/.autosuggest__results ul li {
  padding: 8px;
}
.input-group-append {
  cursor: pointer;
}

.input-group-append:hover i {
  color: #3699ff;
}

.tdTextAlignPrice {
  text-align: right !important;
  vertical-align: middle;
  font-weight: 600;
}
.alert-header {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.orderCode:hover {
  text-decoration: underline;
}
</style>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import moment from 'moment';
import ApiService from '@/core/services/api.service';
import ProductStock from '@/view/components/ProductStock';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import {
  currencyMask,
  makeToastFaile,
  makeToastSuccess,
  unMaskPrice,
  isValidMinQuantity,
} from '@/utils/common';
import decounce from 'debounce';
import { TIME_TRIGGER } from '@/utils/constants';
import ImportExcelStockSlip from '@/view/components/stock-slips/ImportExcelStockSlip';
// import { v4 } from 'uuid';
import _ from 'lodash';
import { cmdUrl } from '@/utils/apiUrl';
import {
  STATUS_CODE,
  DELETE_TYPE,
  AMOUNT_TYPE_VEHICLE,
  AMOUNT_TYPE,
  STOCK_SLIP_TYPE,
  IMEI_STATUS,
  STOCK_SLIP_TYPE_VEHICLE,
  ACCOUNTANT_TYPE,
} from '@/utils/enum';
import productAutosuggestMixin from '@/view/pages/stocks/mixins/product-autosuggest.mixin';
import ProductStockInfoModal from '@/view/components/product-stock/ProductStockInfoModal.vue';
import productStockInfoMixin from '@/view/pages/stocks/mixins/product-stock-info.mixin';

import {
  ProductStockDetailPayload,
  ProductStockPayload,
} from '@/view/pages/stocks/models/ProductStock';

import KTUtil from '@/assets/js/components/util';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';

export default {
  mixins: [validationMixin, productAutosuggestMixin, productStockInfoMixin],
  data() {
    return {
      selectedProductStock: {},
      IMEI_STATUS,
      STOCK_SLIP_TYPE,
      selectedStockSlip: STOCK_SLIP_TYPE.EXPORT_PROVIDER,
      stockSlipOptions: [
        {
          value: STOCK_SLIP_TYPE.EXPORT_PROVIDER,
          text: 'Xuất kho trả hàng NCC',
        },
        // {
        //   value: STOCK_SLIP_TYPE.EXPORT_WARRANTY_TO_PROVIDER,
        //   text: 'Xuất kho bảo hành NCC',
        // },
        // {
        //   value: STOCK_SLIP_TYPE.EXPORT_STOCK_LOSS,
        //   text: 'Xuất kho thất thoát',
        // },
        // {
        //   value: STOCK_SLIP_TYPE.EXPORT_STOCK_CLEARANCE,
        //   text: 'Xuất kho thanh lý',
        // },
      ],
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      btnCreate: {
        fontWeight: '600!important',
        'margin-right': '-10px',
        width: '70px',
      },
      form: {
        providerSelected: null,
        dateImport: '',
      },
      isNew: true,
      errors: [],
      listInventories: [],
      listProviders: [],
      listProduct: [],
      notes: '',
      filteredOptionsProduct: [],
      optionsProduct: [
        {
          data: [],
        },
      ],
      searchProduct: '',
      listTypeSearch: [
        {
          id: 1,
          name: 'Tìm sản phẩm',
        },
        // {
        //   id: 2,
        //   name: 'Tìm theo IMEI',
        // },
      ],
      selectTypeSearch: 1,
      listProductStock: [],
      selectedClassProduct: null,
      filteredOptions: [],
      options: [
        {
          data: [],
        },
      ],
      searchStore: '',
      inventorySelected: null,
      inputSearchProductProp: {
        class: 'autosuggest__input',
        placeholder: 'Tìm kiếm theo tên, mã, mã vạch sản phẩm',
        style: 'border-radius:0px!important',
        disabled: true,
      },
      showOriginalModel: false,
      providerName: '',
      providerPhone: '',
      filteredOptionsProvider: [],
      optionsProvider: [
        {
          data: [],
        },
      ],
      searchProvider: '',
      isSearching: false,
      inputPropStore: {
        class: 'autosuggest__input',
        placeholder: 'Chọn kho',
        style: 'border-radius:0px!important',
        disabled: false,
      },
      purchaseOrderId: null,
      poCode: '',
      cashAmount: 0,
      cashAccountCode: '',
      transferAmount: 0,
      transferAccountCode: '',
      payDate: null,
      filterModel: {
        cashAccountants: [],
        transferAccountants: [],
      },
      formater: {
        currency: currencyMask,
      },
      selectDiscountType: 'AMOUNT',
      listAmountType: [
        {
          id: 'AMOUNT',
          name: 'Tiền mặt',
        },
        {
          id: 'PERSENT',
          name: '%',
        },
      ],
      discountAmount: 0,
      selectVatType: 'AMOUNT',
      vatAmount: 0,
      vatCode: '',
      vatDate: '',
      filteredOptionsCash: [],
      optionsCash: [
        {
          data: [],
        },
      ],
      searchCash: '',
      filteredOptionsTransfer: [],
      optionsTransfer: [
        {
          data: [],
        },
      ],
      searchTransfer: '',
      isBusy: false,
    };
  },
  validations: {
    form: {
      providerSelected: { required },
      dateImport: { required },
    },
    searchStore: { required },
    vatDate: { required },
  },
  components: {
    KTCodePreview,
    ProductStock,
    ImportExcelStockSlip,
    ProductStockInfoModal,
    Autosuggest,
  },
  created() {
    this.fetchStoreByUser();
    this.fetchProvider();
    // this.fetchAccountants('cash');
    this.fetchAccountants('transfer');
  },
  mounted() {
    this.$v.form.dateImport.$model = moment().format('DD/MM/YYYY');
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Phiếu xuất kho', route: 'export-stocks' },
      { title: 'Tạo phiếu xuất kho' },
    ]);
    if (this.$route.query.purchaseOrderId) {
      this.purchaseOrderId = this.$route.query.purchaseOrderId;
      this.getInfoById();
    }
  },
  computed: {
    totalPrice() {
      let total = 0;
      for (let index = 0; index < this.listProductStock.length; index++) {
        const element = this.listProductStock[index];
        total +=
          unMaskPrice(element.price) * element.quantity -
          unMaskPrice(element.discount);
      }

      const discountValue = this.discountAmount
        ? unMaskPrice(this.discountAmount)
        : 0;
      let discountAmount = discountValue;
      if (this.selectDiscountType === AMOUNT_TYPE.PERCENT) {
        discountAmount = (discountValue * total) / 100;
      }

      total = total - discountAmount;
      const vatValue = this.vatAmount ? unMaskPrice(this.vatAmount) : 0;

      let vatAmount = vatValue;
      if (this.selectVatType === AMOUNT_TYPE.PERCENT) {
        vatAmount = (vatValue * total) / 100;
      }

      total = parseInt(total) + parseInt(vatAmount);
      return total;
    },
    totalQuantity() {
      let total = 0;
      for (let index = 0; index < this.listProductStock.length; index++) {
        const element = this.listProductStock[index];
        total += element.quantity * 1;
      }
      return total;
    },
    showSelectProvider() {
      return ![
        STOCK_SLIP_TYPE.EXPORT_STOCK_LOSS,
        STOCK_SLIP_TYPE.EXPORT_STOCK_CLEARANCE,
      ].includes(this.selectedStockSlip);
    },
    showInfoVat() {
      return this.selectedStockSlip === STOCK_SLIP_TYPE.EXPORT_PROVIDER;
    },
  },
  methods: {
    validateState(name) {
      if (!this.isNew) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      }
    },
    onInputChangProduct(text) {
      if (!text) {
        text = '';
      }
      this.searchProduct = text;
      this.debounceInputProduct();
    },
    formatMoney(number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    // onSubmit: async function () {
    //   const stockLossAndClearance = [
    //     STOCK_SLIP_TYPE.EXPORT_STOCK_LOSS,
    //     STOCK_SLIP_TYPE.EXPORT_STOCK_CLEARANCE,
    //   ].includes(this.selectedStockSlip);

    //   if (!this.$v.form.providerSelected.$model && !stockLossAndClearance) {
    //     makeToastFaile('Vui lòng chọn nhà cung cấp!');
    //     return;
    //   }

    //   let isMinQuantity = true;
    //   isMinQuantity = isValidMinQuantity(this.$refs.product_stock);

    //   if (!isMinQuantity) {
    //     makeToastFaile('Vui lòng nhập số lượng lớn hơn bằng 1');
    //     return;
    //   }

    //   const listPro = this.listProductStock.map((element) => {
    //     element.IMEI = element.IMEI.trim();
    //     return {
    //       productId: element.proId,
    //       imeis: element.IMEI.replace(/[\n\r]/g, ', '),
    //       quantity: element.quantity,
    //       unitPrice: element.price ? unMaskPrice(element.price) : 0,
    //       discount: element.discount ? unMaskPrice(element.discount) : 0,
    //     };
    //   });
    //   const data = {
    //     storeId: this.inventorySelected,
    //     providerId: this.$v.form.providerSelected.$model,
    //     description: this.notes,
    //     importDate: this.$v.form.dateImport.$model
    //       ? moment(this.$v.form.dateImport.$model, 'DD/MM/YYYY').format(
    //           'yyyy-MM-DD',
    //         )
    //       : '',
    //     listProduct: listPro,
    //     cashAmount: this.cashAmount ? unMaskPrice(this.cashAmount) : 0,
    //     cashAccountCode: this.cashAccountCode,
    //     transferAmount: this.transferAmount
    //       ? unMaskPrice(this.transferAmount)
    //       : 0,
    //     transferAccountCode: this.transferAccountCode,
    //     payDate: this.payDate
    //       ? moment(this.payDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
    //       : null,
    //     vatDate: this.vatDate
    //       ? moment(this.vatDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
    //       : null,
    //     vatType: this.selectVatType,
    //     vatAmount: this.vatAmount ? unMaskPrice(this.vatAmount) : 0,
    //     vatCode: this.vatCode,
    //     discountType: this.selectDiscountType,
    //     discountAmount: this.discountAmount
    //       ? unMaskPrice(this.discountAmount)
    //       : 0,
    //     purchaseOrderId: this.purchaseOrderId,
    //     purchaseOrderCode: this.poCode,
    //     stockSlipType: this.selectedStockSlip,
    //   };
    //   if (this.isNew === true && !this.isBusy) {
    //     this.isNew = false;
    //     this.isBusy = true;
    //     ApiService.post('stock-slips/export-stock-slip', data)
    //       .then(({ data }) => {
    //         if (data.status === STATUS_CODE.SUCCESS) {
    //           makeToastSuccess(data.message);
    //           setTimeout(() => {
    //             this.$router.push({
    //               name: 'update-export-stock',
    //               query: { id: data.data },
    //             });
    //           }, TIME_TRIGGER);
    //           this.isNew = true;
    //         } else {
    //           makeToastFaile(data.message);
    //           this.isNew = true;
    //         }
    //       })
    //       .catch((response) => {
    //         makeToastFaile(response);
    //         this.isNew = true;
    //       })
    //       .finally(() => {
    //         this.isBusy = false;
    //       });
    //   }
    // },
    onSubmit: async function () {
      const stockLossAndClearance = [
        STOCK_SLIP_TYPE.EXPORT_STOCK_LOSS,
        STOCK_SLIP_TYPE.EXPORT_STOCK_CLEARANCE,
      ].includes(this.selectedStockSlip);

      this.$v.vatDate.$touch();
      console.log(this.vatDate);

      if (this.$v.vatDate.$anyError) {
        KTUtil.scrollTop();
        makeToastFaile('Vui lòng nhập ngày xuất VAT!');
        return;
      }

      if (!this.$v.form.providerSelected.$model && !stockLossAndClearance) {
        makeToastFaile('Vui lòng chọn nhà cung cấp!');
        return;
      }

      let isMinQuantity = true;
      isMinQuantity = isValidMinQuantity(this.$refs.product_stock);

      if (!isMinQuantity) {
        makeToastFaile('Vui lòng nhập số lượng lớn hơn bằng 1');
        return;
      }
      const stockSlipDetails = this.listProductStock.map((element) => {
        return Object.assign(
          {},
          new ProductStockDetailPayload({
            productId: element.proId,
            quantity: element.quantity,
            unitPrice: element.price ? parseInt(unMaskPrice(element.price)) : 0, // đang lấy theo import price
            discountAmount: element.discount
              ? parseInt(unMaskPrice(element.discount))
              : 0,
            discountType: AMOUNT_TYPE_VEHICLE.AMOUNT,
            chassisNumber: element.chassisNumber,
            engineNumber: element.engineNumber,
            serialNumber: element.serialNumber,
          }),
        );
      });
      const data = new ProductStockPayload({
        type: STOCK_SLIP_TYPE_VEHICLE.EXPORT_STOCK,
        sourceWarehouseId: this.inventorySelected,
        providerId: this.$v.form.providerSelected.$model,
        description: this.notes,
        importDate: this.$v.form.dateImport.$model
          ? moment(this.$v.form.dateImport.$model, 'DD/MM/YYYY').format(
              'yyyy-MM-DD',
            )
          : '',
        vatDate: this.vatDate
          ? moment(this.vatDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : '',
        cashAmount: this.cashAmount
          ? parseInt(unMaskPrice(this.cashAmount))
          : 0,
        cashAccountCode: this.cashAccountCode,
        transferAmount: this.transferAmount
          ? parseInt(unMaskPrice(this.transferAmount))
          : 0,
        transferAccountCode: this.transferAccountCode,
        totalAmount: !isNaN(Number(this.totalPrice))
          ? Number(this.totalPrice)
          : 0,
        discountType: this.selectDiscountType,
        discountAmount: this.discountAmount
          ? parseInt(unMaskPrice(this.discountAmount))
          : 0,
        // vatSerial: this.$v.form.vatSerial.$model,
        vatCode: this.vatCode,
        stockSlipDetails: stockSlipDetails,
      });

      try {
        const res = await ApiService.post(cmdUrl.StockSlip.root, data);
        if (res.data.status === STATUS_CODE.SUCCESS) {
          makeToastSuccess(res.data.message);
          setTimeout(() => {
            this.$router.push({
              name: 'update-export-stock',
              query: { id: res.data.data.id },
            });
          }, TIME_TRIGGER);
        } else {
          makeToastFaile(res.data.message);
        }
      } catch ({ response }) {
        makeToastFaile(response.data.message || 'lỗi!!');
      } finally {
        this.isSubmitting = false;
      }
    },
    cancelAdd() {
      this.listProduct.shift();
    },
    save(item) {
      this.listProduct[0] = item;
    },
    fetchStore() {
      ApiService.setHeader();
      ApiService.get(`stores?limit=10000&page=1`).then(({ data }) => {
        this.listInventories = data.data.list_store;
        this.listInventories.map((element) => {
          let store = {
            id: element.id,
            name: element.name,
            shortName: element.shortName,
            suggestionName: element.shortName + ' ( ' + element.name + ')',
          };
          this.options[0].data.push(store);
        });
        let tmp = [{ data: this.options[0].data }];
        this.filteredOptions = tmp;
      });
    },
    fetchProvider() {
      ApiService.setHeader();
      ApiService.get(
        `${cmdUrl.ProviderUrl.root}?search=${this.searchProvider}`,
      ).then(({ data }) => {
        this.listProviders = data.data.data;
        this.listProviders.map((element) => {
          let provider = {
            id: element.id,
            name: element.name,
            suggestionName: element.name,
          };
          this.optionsProvider[0].data.push(provider);
        });
        let tmp = [{ data: this.optionsProvider[0].data }];
        this.filteredOptionsProvider = tmp;
      });
    },
    // fetchProduct(textSearch) {
    //   this.isSearching = true;
    //   this.listProduct = [];
    //   const checkQuantityType =
    //     this.selectedStockSlip === STOCK_SLIP_TYPE.EXPORT_WARRANTY_TO_PROVIDER
    //       ? 'warrantyQuantity'
    //       : 'inStockQuantity';
    //   const params = new URLSearchParams({
    //     storeId: this.inventorySelected,
    //     searchProduct: textSearch,
    //     checkQuantityType: checkQuantityType,
    //   });
    //   ApiService.get(`stocks/get-product-by-store?${params.toString()}`)
    //     .then(({ data }) => {
    //       this.optionsProduct[0].data = [];
    //       const products = data.data;
    //       this.listProduct = products;
    //       products.map((element) => {
    //         this.optionsProduct[0].data.push(element);
    //       });
    //       this.filteredOptionsProduct = [
    //         {
    //           data: this.optionsProduct[0].data,
    //         },
    //       ];
    //       this.isSearching = false;
    //       if (textSearch !== this.searchProduct) {
    //         let nextSearchProduct = this.searchProduct;
    //         this.fetchProduct(nextSearchProduct);
    //       }
    //     })
    //     .catch(() => (this.isSearching = false));
    // },
    // fetchProduct(textSearch) {
    //   this.isSearching = true;
    //   this.listProduct = [];
    //   // @TODO: Xử lý lấy sản phẩm theo cửa hàng.
    //   // ApiService.get( `productSearch/withMerchant?storeId=${this.inventorySelected}&searchProduct=${textSearch}`,)
    //   ApiService.get(
    //     `products?storeId=${this.inventorySelected}&search=${textSearch}&deleted=${DELETE_TYPE.AVAILABLE}`,
    //   ).then(({ data }) => {
    //     this.optionsProduct[0].data = [];
    //     const products = data.data.data;
    //     this.listProduct = products;
    //     products.map((element) => {
    //       this.optionsProduct[0].data.push(element);
    //     });
    //     this.filteredOptionsProduct = [...this.optionsProduct[0].data];
    //     this.isSearching = false;
    //     if (textSearch !== this.searchProduct) {
    //       let nextSearchProduct = this.searchProduct;
    //       this.fetchProduct(nextSearchProduct);
    //     }
    //   });
    // },
    fetchProduct(textSearch) {
      this.isSearching = true;
      this.listProduct = [];
      ApiService.get(
        `products?storeId=${this.inventorySelected}&search=${textSearch}&deleted=${DELETE_TYPE.AVAILABLE}`,
      )
        .then(({ data }) => {
          this.optionsProduct[0].data = [];
          const products = data.data.data;
          this.listProduct = products;
          products.map((element) => {
            this.optionsProduct[0].data.push(element);
          });
          this.filteredOptionsProduct = [
            {
              data: this.optionsProduct[0].data,
            },
          ];
          this.isSearching = false;
          if (textSearch !== this.searchProduct) {
            let nextSearchProduct = this.searchProduct;
            this.fetchProduct(nextSearchProduct);
          }
        })
        .catch(() => (this.isSearching = false));
    },
    deleteItemInArray(id) {
      for (var i = 0; i < this.listProductStock.length; i++) {
        if (this.listProductStock[i].id === id) {
          this.listProductStock.splice(i, 1);
        }
      }
    },
    updateItem(item) {
      this.listProductStock.forEach((element, index) => {
        if (element.id === item.id) {
          this.listProductStock[index].name = item.name;
          this.listProductStock[index].IMEI = item.IMEI;
          this.listProductStock[index].proId = item.proId;
          this.listProductStock[index].price = item.price;
          this.listProductStock[index].quantity = parseInt(item.quantity);
          this.listProductStock[index].totalPrice = item.totalPrice;
        }
      });
    },
    onChangeStore() {
      this.listProductStock = [];
      this.fetchAccountants('cash');
    },
    fetchProductImei() {
      this.listProduct = [];
      ApiService.get(
        `stocks/get-product-by-store-imei?storeId=${this.inventorySelected}`,
      ).then(({ data }) => {
        this.optionsProduct[0].data = [];
        const products = data.data;
        this.listProduct = products;
        products.map((element) => {
          this.optionsProduct[0].data.push(element);
        });
        this.filteredOptionsProduct = [
          {
            data: this.optionsProduct[0].data,
          },
        ];
      });
    },
    onSelectedStore(option) {
      this.searchStore = option.item.name;
      this.inventorySelected = option.item.id;
      this.inputSearchProductProp.disabled = false;
      this.onChangeStore();
    },
    onInputChange(text) {
      if (text === '') {
        this.inputSearchProductProp.disabled = true;
      }
      this.searchStore = text;
      const filteredData = this.options[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
      this.filteredOptions = [
        {
          data: filteredData,
        },
      ];
      //this.onChangeStore();
    },
    createProvider: async function () {
      if (!this.providerName || this.isBusy) return;
      this.isBusy = true;
      const data = {
        name: this.providerName,
        phoneNumber: this.providerPhone,
      };

      try {
        const response = await ApiService.post('providers', data);
        const { status, message } = response.data.data;
        if (status === STATUS_CODE.SUCCESS) {
          this.fetchProvider();
          this.showOriginalModel = !this.showOriginalModel;
          makeToastSuccess(message);
        } else {
          this.showOriginalModel = !this.showOriginalModel;
          makeToastFaile(message);
        }
      } catch (err) {
        const { message } = err.response.data;
        return makeToastFaile(message || 'Lỗi');
      } finally {
        this.isBusy = false;
      }
    },
    searchProductAPI() {
      if (!this.isSearching) {
        let textSearch = this.searchProduct;
        if (this.selectTypeSearch === 2) {
          this.fetchProductImei();
        } else {
          this.fetchProduct(textSearch);
        }
      }
    },
    debounceInputProduct: decounce(function () {
      this.searchProductAPI();
    }, TIME_TRIGGER),
    fetchStoreByUser() {
      // @TODO: Lấy thông tin các cửa hàng theo người dùng
      // ApiService.get(`stores/get-by-user`).then(({ data }) => {
      ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === STATUS_CODE.SUCCESS) {
          const stores = data.data.stores;
          if (stores.length === 1) {
            this.inventorySelected = stores[0].id;
            this.searchStore = stores[0].name;
            this.inputPropStore.disabled = true;
            this.inputSearchProductProp.disabled = false;
            this.fetchAccountants('cash');
          } else {
            this.options[0].data = [];
            stores.map((element) => {
              let store = {
                id: element.id,
                name: element.name,
                shortName: element.shortName,
                suggestionName: element.shortName + ' ( ' + element.name + ')',
              };
              this.options[0].data.push(store);
            });
            let tmp = [{ data: this.options[0].data }];
            this.filteredOptions = tmp;
          }

          // @TODO: Lấy thông tin tài khoản kế toán theo tiền mặt
          // this.fetchAccountants('cash');
          // this.fetchAccountantsMock('cash');
        }
      });
    },

    // fetchStoreByUser() {
    //   ApiService.setHeader();
    //   ApiService.get(`stores/get-by-user`).then(({ data }) => {
    //     if (data.status === STATUS_CODE.SUCCESS) {
    //       const stores = data.data.stores;
    //       if (stores.length === 1) {
    //         this.inventorySelected = stores[0].id;
    //         this.searchStore = stores[0].name;
    //         this.inputPropStore.disabled = true;
    //         this.inputSearchProductProp.disabled = false;
    //         this.fetchAccountants('cash');
    //       } else {
    //         this.options[0].data = [];
    //         stores.map((element) => {
    //           let store = {
    //             id: element.id,
    //             name: element.name,
    //             shortName: element.shortName,
    //             suggestionName: element.shortName + ' ( ' + element.name + ')',
    //           };
    //           this.options[0].data.push(store);
    //         });
    //         let tmp = [{ data: this.options[0].data }];
    //         this.filteredOptions = tmp;
    //       }
    //     }
    //   });
    // },
    onSelectedProvider(option) {
      this.searchProvider = option.item.name;
      this.$v.form.providerSelected.$model = option.item.id;
    },
    onInputChangeProvider(text) {
      this.searchProvider = text;
      const filteredData = this.optionsProvider[0].data
        .filter((item) => {
          return item.name.toLowerCase().indexOf(text.toLowerCase()) > -1;
        })
        .slice(0, this.limit);
      this.filteredOptionsProvider = [
        {
          data: filteredData,
        },
      ];
    },
    showModalImportProduct() {
      this.$refs['stock-slip-excel'].showModal();
    },
    validData(data) {
      data.forEach((element) => {
        const item = {
          id: element.id,
          name: element.productName,
          productCode: element.productCode,
          barCode: element.barCode,
          totalQuantityInStock: element.totalQuantityInStock,
          IMEI: element.imeiCode,
          proId: element.productId,
          price: element.unitPrice,
          quantity: element.quantity,
          discount: element.discountAmount,
          totalPrice: element.totalAmount,
          productType: element.productType,
        };
        this.listProductStock.push(item);
      });
    },
    getInfoById() {
      ApiService.get(`purchase-order/${this.purchaseOrderId}`)
        .then(({ data }) => {
          this.poCode = data.data.code ? data.data.code : '';
          this.notes = data.data.description ? data.data.description : '';
          this.searchStore = data.data.storeName ? data.data.storeName : '';
          this.inventorySelected = data.data.storeId;
          this.searchProvider = data.data.providerName
            ? data.data.providerName
            : '';
          this.$v.form.providerSelected.$model = data.data.providerId;
          this.discountAmount = data.data.discountAmount
            ? data.data.discountAmount
            : 0;
          this.vatAmount = data.data.vatAmount ? data.data.vatAmount : 0;
          this.selectDiscountType = data.data.discountType
            ? data.data.discountType
            : 1;
          this.selectVatType = data.data.vatType ? data.data.vatType : 2;
          this.vatDate = data.data.vatDate
            ? moment(data.data.vatDate).format('DD-MM-YYYY')
            : '';
          this.searchCash = data.data.cashAccountName
            ? data.data.cashAccountName
            : '';
          this.searchTransfer = data.data.transferAccountName
            ? data.data.transferAccountName
            : '';
          this.vatCode = data.data.vatCode ? data.data.vatCode : '';
          this.listProductStock = data.data.details.map((element) => {
            const totalAmount =
              element.quantity * element.unitPrice - element.discountAmount;
            return {
              totalAmount: totalAmount,
              id: element.id,
              name: element.productName,
              IMEI: '',
              proId: element.productId,
              price: element.unitPrice,
              quantity: element.quantity,
              totalPrice: totalAmount,
              productType: element.productType,
              totalQuantityInStock: element.inStockQuantity,
              warrantyQuantity: element.warrantyQuantity,
              discount: element.discountAmount ? element.discountAmount : 0,
              barCode: element.barCode ? element.barCode : '',
              productCode: element.productCode ? element.productCode : '',
            };
          });
          this.fetchAccountants('cash');
        })
        .catch((response) => {
          makeToastFaile(response);
        });
    },
    linkToPO: function (poCode) {
      const { href } = this.$router.resolve({
        name: 'info-purchase-orders',
        query: { id: poCode },
      });
      window.open(href, '_blank');
    },
    onSelectedCash(option) {
      console.log(option);

      this.searchCash = option.item.name;
      this.cashAccountCode = option.item.code;
    },
    onInputChangeCash(text) {
      this.searchCash = text;
      const filteredData = this.optionsCash[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.code.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
      this.filteredOptionsCash = [
        {
          data: filteredData,
        },
      ];
    },
    onSelectedTransfer(option) {
      this.searchTransfer = option.item.name;
      this.transferAccountCode = option.item.code;
    },
    onInputChangeTransfer(text) {
      this.searchTransfer = text;
      const filteredData = this.optionsTransfer[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.code.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
      this.filteredOptionsTransfer = [
        {
          data: filteredData,
        },
      ];
    },
    // fetchAccountants: async function (mode) {
    //   this.optionsCash[0].data = [];
    //   let params = {
    //     type: mode === 'cash' ? '1' : '2',
    //   };
    //   let url = cmdUrl.AccountantUrl.byType;
    //   if (this.inventorySelected !== null && mode === 'cash') {
    //     url = 'accountants/getByStore';
    //     params = {
    //       type: mode === 'cash' ? '1' : '2',
    //       storeId: this.inventorySelected,
    //     };
    //   }
    //   ApiService.query(url, {
    //     params,
    //   }).then((response) => {
    //     const data = response.data.data || [];
    //     _.map(data, (item) => {
    //       const element = {
    //         code: item.code,
    //         name: item.name,
    //         suggestionName: `(${item.code}) - ${item.name}`,
    //       };
    //       if (mode === 'cash') {
    //         this.optionsCash[0].data.push(element);
    //       } else if (mode === 'transfer') {
    //         if (item.level === 2) this.optionsTransfer[0].data.push(element);
    //       }
    //     });
    //     let tmpCash = [{ data: this.optionsCash[0].data }];
    //     this.filteredOptionsCash = tmpCash;
    //     let tmpTransfer = [{ data: this.optionsTransfer[0].data }];
    //     this.filteredOptionsTransfer = tmpTransfer;
    //   });
    // },
    fetchAccountants: async function (mode) {
      this.optionsCash[0].data = [];
      let params = {
        type: mode === 'cash' ? ACCOUNTANT_TYPE.CASH : ACCOUNTANT_TYPE.TRANSFER,
      };
      let url = cmdUrl.AccountantUrl.byType;
      if (this.inventorySelected !== null && mode === 'cash') {
        url = 'accountants/getByStore';
        params = {
          type:
            mode === 'cash' ? ACCOUNTANT_TYPE.CASH : ACCOUNTANT_TYPE.TRANSFER,
          storeId: this.inventorySelected,
        };
      }
      ApiService.query(url, {
        params,
      }).then((response) => {
        const data = response.data.data || [];
        _.map(data, (item) => {
          let element = {
            code: item.code,
            name: item.name,
            suggestionName: `(${item.code}) - ${item.name}`,
          };
          if (mode === 'cash') {
            this.optionsCash[0].data.push(element);
          } else if (mode === 'transfer') {
            if (item.level >= 2) {
              this.optionsTransfer[0].data.push(element);
            }
          }
        });
        this.filteredOptionsCash = [...this.optionsCash[0].data];
        this.filteredOptionsTransfer = [...this.optionsTransfer[0].data];
      });
    },
  },
};
</script>
